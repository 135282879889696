<template>
  <div class="iot">
    <div class="hxcp"></div>
    <!-- 内容一 -->
    <div class="content1 container">
      <show :styles="{ top: '7.375em', left: '-10em' }" :moveData="{ left: '3.9em' }">
        <div class="top_left">
          <p class="title1">公用事业专属物联网平台</p>
          <p class="title2">LinkIoT</p>
          <p class="border"></p>
          <p class="text">
            领氪科技以物联网、大数据、边缘计算等技术为支撑,
            打造公用事业领域的专属工业级物联网平台，支持海量异构设备的快速接入和全面感知，并深度融合公用事业领域（水、电、气）的专业化场景，助力企业快速搭建工业级的物联网应用。
          </p>
        </div>
      </show>
      <show :styles="{ top: '6.25em', left: '30em' }" :moveData="{ left: '38em' }">
        <img class="top_right" src="../../assets/images/bg_img/LinkIoT.png" alt=""
        /></show>
      <show :styles="{ top: '28em', left: '30em' }" :moveData="{ left: '35em' }">
        <p class="common_title title_sty">平台概览</p></show
      >
      <show :styles="{ top: '33em', left: '-10em' }" :moveData="{ left: '4em' }">
        <img class="gl_img" src="../../assets/images/bg_img/LinkIoT3.png" alt=""/>
      </show>
    </div>
    <!-- 内容二 -->
    <div class="content2">
      <div class="container">
        <show :styles="{ top: '3.2em', left: '-10em' }" :moveData="{ left: '35em' }">
          <p class="common_title title_sty">平台架构</p></show
        >
        <show :styles="{ top: '10em', left: '30em' }" :moveData="{ left: '16.625em' }">
          <img src="../../assets/images/bg_img/LinkIot1.png" alt=""/>
        </show>
      </div>
    </div>

    <!-- 内容三 -->
    <div class="content3 container">
      <show :styles="{ top: '2.2em', left: '30em' }" :moveData="{ left: '35em' }">
        <p class="common_title title_sty">平台特点</p>
      </show>
      <div class="wrapper">
        <show :styles="{ top: '7.625em', left: '10em' }" :moveData="{ left: '2.5em' }">
          <ul
              class="infos"
              :class="flag ? (show ? 'shows' : 'hidden') : ''"
              @mouseenter="
              show = true;
              flag = 1;
            "
              @mouseleave="
              show = false;
              flag = 1;
            "
          >
            <li v-for="(e, i) in infos" :key="i">
              <img :src="e.src" alt=""/>
              <p class="title">{{ e.title }}</p>
              <p class="text" v-for="(e2, i) in e.texts" :key="i">{{ e2 }}</p>
            </li>
          </ul>
        </show>
      </div>
    </div>

    <!-- 内容四 -->

    <div class="content4 container">
      <show :styles="{ top: '2.2em', left: '-10em' }" :moveData="{ left: '35em' }">
        <p class="common_title title_sty">应用场景</p></show
      >
      <show :styles="{ top: '6.625em', left: '30em' }" :moveData="{ left: '8em' }">
        <ul class="imgs">
          <li v-for="(e, i) in imgs" :key="i" @click="playVideo(e)">
            <p class="icon_gbs"></p>
            <img class="video_img" :src="e.img" alt=""/>
          </li>
        </ul>
      </show>
      <show :styles="{ top: '18em', left: '30em' }" :moveData="{ left: '8em' }">
        <ul class="titles">
          <li v-for="(e, i) in titles" :key="i">
            <p>{{ e.title }}</p>
            <p style="color: #999999">{{ e.title1 }}</p>
          </li>
        </ul>
      </show>
    </div>
    <el-dialog
        v-show="videoShow"
        :show-close="false"
        :close-on-click-modal="false"
        :visible.sync="videoShow"
        width="68.75em"
    >
      <!-- <p class="video_play_icon" v-show="showPlay ? 'showPlay' : 'hiddenPlay'"></p> -->
      <p class="dialog_play_icon" @click="stopVideo"></p>
      <div @click="showPlay = !showPlay">
        <video ref="video" controls width="100%" height="100%" :src="video_src"></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoShow: false,
      video_src: "",
      show: false,
      showPlay: true,
      flag: 0,
      infos: [
        {
          src: require("../../assets/images/iot_icons/icon_1.png"),
          title: "轻量化、敏捷化部署",
          texts: [
            "支持轻量化、敏捷化部署，减少企业硬件投入和运维",
            "成本，可使业务人员更关注业务实现",
            "而不受底层技术和运维能力的桎梏",
          ],
        },

        {
          src: require("../../assets/images/iot_icons/icon_2.png"),
          title: "开放的三方应用支持能力",
          texts: [
            "提供多种开放接口(RestfulAPI, WebService, AMQP",
            "等)，支持快速数据共享，降低应用开发成本",
            "保障数据完整、提高数据利用率",
          ],
        },

        {
          src: require("../../assets/images/iot_icons/icon_3.png"),
          title: "简单快速的设备接入能力",
          texts: [
            "支持多种接入方式，同时拥有自研的工业级物联网关",
            "(LinkEdge)，可全面接入海量异构的用能设备数据",
            "规范数据接入标准，降低数据接入成本，可实现无缝",
            "对接、高实时、低延迟、海量在线数据的处理能力",
          ],
        },

        {
          src: require("../../assets/images/iot_icons/icon_4.png"),
          title: "场景化的功能组件",
          texts: [
            "提供面向公用事业场景化的功能组件",
            "如:水厂数据、电厂数据、场站数据、管网数据等",
            "快速定义各种类型和业务的设备分组",
            "使设备清晰，数据明了",
          ],
        },

        {
          src: require("../../assets/images/iot_icons/icon_5.png"),
          title: "强大的数据可视化能力",
          texts: [
            "利用LinkView丰富的可视化组件",
            "无需专业复杂组态软件，进行专业级大屏展示",
            "实时掌握设备的动态信息，结合数字孪生技术",
            "映射真实场景，使数据能直观感知",
          ],
        },

        {
          src: require("../../assets/images/iot_icons/icon_6.png"),
          title: "从设备到云的多重安全保障",
          texts: [
            "领氪iLink物联网平台提供多重防护手段",
            "有效保障设备和云端数据的安全",
            "通过设备安全认证，通讯数据加密",
            "身份认证等方式保障平台的安全和数据的完整",
          ],
        },
      ],
      imgs: [
        {
          img: require("../../assets/images/bg_img/video_1.png"),
          video: require("../../assets/videos/water_1.mp4"),
        },
        {
          img: require("../../assets/images/bg_img/video_2.png"),
          video: require("../../assets/videos/scada_1.mp4"),
        },
        {
          img: require("../../assets/images/bg_img/video_3.png"),
          video: require("../../assets/videos/stand_1.mp4"),
        },
        {
          img: require("../../assets/images/bg_img/video_4.png"),
          video: require("../../assets/videos/fuelGas_1.mp4"),
        },
      ],
      titles: [
        {
          title: "数字孪生水厂",
          title1: "Digital twin water plant",
        },
        {
          title: "SCADA供水调度系统 ",
          title1: "Water supply dispatching",
        },
        {
          title: "数字三维厂站",
          title1: "Digital 3D plant and station",
        },
        {
          title: "燃气泄漏监测平台",
          title1: "Gas leakage monitoring platform",
        },
      ],
    };
  },
  methods: {
    playVideo(e) {
      this.video_src = e.video;
      this.videoShow = true;
    },
    stopVideo() {
      let video = this.$refs.video;
      video.currentTime = 0;
      video.pause();
      this.videoShow = false;
      this.showPlay = true;
    },
  },
};
</script>

<style lang="less" scoped>
.content1 {
  height: 53.52em;

  .top_left {
    top: 7.375em;
  }

  .top_right {
    width: 36.125em;
  }
}

.content2 {
  background: url("../../assets/images/bg_img/hf (2).jpg") no-repeat transparent;
  height: 40em;

  .title_sty {
    top: 3.2em;
    color: #fff;
  }

  img {
    width: 43.75em;
  }
}

.content3 {
  height: 34.875em;

  .title_sty {
    color: #000;
  }

  .wrapper {
    width: 72.5em;
    height: 22.8125em;
  }

  .infos {
    position: absolute;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    width: 72.3em;
    height: inherit;
    border: 1px dashed #fff;

    img {
      width: 2.2em;
    }

    li {
      width: 30%;
    }

    .title {
      color: #2969e3;
      font-weight: bold;
      font-size: 1.1em;
      margin-top: 0.3em;
      margin-bottom: 0.8em;
    }

    .text {
      font-size: 0.9em;
      color: #858587;
      font-weight: 600;
    }
  }
}

.content4 {
  height: 26.125em;

  .title_sty {
    color: #000;
  }

  .imgs {
    width: 60.75em;
    top: 6.625em;
    display: flex;

    li {
      position: relative;
      margin-right: 1.25em;
      cursor: pointer;

      .video_btn {
        position: absolute;
        width: 3.75em;
        height: 3.75em;
      }

      .video_img {
        width: 14.375em;
        height: 10em;
      }
    }
  }

  .titles {
    width: 60.75em;
    display: flex;

    li {
      position: relative;
      min-width: 16.375em;
      margin-right: 1.25em;
      font-size: 0.9em;
    }
  }
}

.icon_gbs {
  position: absolute;
  width: 3.75em;
  height: 3.75em;
  background: url("../../assets/images/all_icons.png") -2308px -845px no-repeat;
  left: 50%;
  top: 50%;
  margin-top: -30px;
  margin-left: -30px;
}

.shows {
  animation: show 1s ease forwards;
}

.hidden {
  animation: hidden 1s ease forwards;
}

.common_title {
  width: 160px;
  font-size: 1.9em;
  left: 1000px;
}

.gl_img {
  width: 69.25em;
  height: 11.25em;
  z-index: 50;
  top: 33em;
  left: 5000px;
}

.video_play_icon {
  position: absolute;
  width: 5em;
  height: 5em;
  background: url("../../assets/images/all_icons.png") -2308px -845px no-repeat;
  left: 50%;
  top: 50%;
  margin-top: -30px;
  margin-left: -30px;
}

.dialog_play_icon {
  position: absolute;
  width: 3em;
  height: 3em;
  background: url("../../assets/images/all_icons.png") -2340px -590px no-repeat;
  left: 95%;
  top: 3%;
  z-index: 10;
  cursor: pointer;
}

@keyframes show {
  from {
    border-color: #fff;
  }
  to {
    border-color: #ccc;
  }
}

@keyframes hidden {
  from {
    border-color: #ccc;
  }
  to {
    border-color: #fff;
  }
}

.showPlay {
  animation: showPlay 1s ease forwards;
}

.hiddenPlay {
  animation: hiddenPlay 1s ease forwards;
}

@keyframes showPlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hiddenPlay {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

video {
  cursor: pointer;
  border: none;
}
</style>
<style>
.el-dialog__body,
.el-dialog__header {
  padding: 0;
}

.el-dialog,
.el-pager li {
  background: none;
}
</style>
